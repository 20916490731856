/**
 * @file このアプリケーションの共通機能を提供するコンポーネントです。
 */
import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import React, { lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router";
import { HomePage } from "pages/homePages/HomePage";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "react-app-polyfill/stable";
import "custom.css";
import { useEffect } from "react";
// import AppRouter from "./AppRouter";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SnackbarProvider, useSnackbar } from "notistack";
import { MessageContext } from "components/context/MessageContext";
import axios from "axios";
import { GetRevision } from "components/api/CheckRevisionApi";
import { sessionStorageKeys } from "components/common/Const";
import { CircularProgress } from "@material-ui/core";
import { commonStyle } from "components/styles/CommonStyle";

const AppRouter = lazy(() => import("./AppRouter"));

export default function App() {
  const unmountRef = useUnmountRef();
  const styles = commonStyle();
  const displayName = App.name;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const [closeSnackbarWhenlocationChange, setCloseSnackbarWhenlocationChange] =
    useSafeState(unmountRef, []);

  window.onbeforeunload = (event) => {
    // // リロードされたときに、バージョン等の情報を初期化する
    console.log("App.js　onbeforeunload");
    // console.log("リロード!!!");
    // var setRevision = async () => {
    //   await GetRevision().then((revision) => {
    //     sessionStorage.setItem(sessionStorageKeys.Revision, revision);
    //   });
    // };
    // // バージョンを更新
    // setRevision();
    sessionStorage.removeItem(sessionStorageKeys.Revision);
    // // リロードメッセージを削除
    // sessionStorage.removeItem(sessionStorageKeys.Reload);
    // // サーバーバージョンを削除
    // sessionStorage.removeItem(sessionStorageKeys.ServerRevision);
  };

  useEffect(() => {
    //axiosでキャッシュしない設定
    //IE11でaxiosがキャッシュする動作の対応
    axios.defaults.headers["Pragma"] = "no-cache";
    sessionStorage.setItem(sessionStorageKeys.Revision, "init");

    var setRevisionFn = async () => {
      await GetRevision().then((revision) => {
        sessionStorage.setItem(sessionStorageKeys.Revision, revision);
      });
    };
    // バージョンを更新
    console.log("App.js初期化");
    setRevisionFn();
  }, []);

  useEffect(() => {
    if (closeSnackbarWhenlocationChange.length > 0) {
      closeSnackbarWhenlocationChange.forEach((key, index) => {
        closeSnackbar(key);
      });
      setCloseSnackbarWhenlocationChange([]);
    }
  }, [location]);

  return (
    <MessageContext.Provider
      value={{
        enqueue: (message, type) => {
          // setSnackbarClose(true);
          var key = enqueueSnackbar(message, {
            variant: type,
          });
          closeSnackbarWhenlocationChange.push(key);
          setCloseSnackbarWhenlocationChange(closeSnackbarWhenlocationChange);
        },
        enqueueNotClose: (message, type) => {
          enqueueSnackbar(message, {
            variant: type,
          });
        },
        enqueueWithOption: (message, option) => {
          var key = enqueueSnackbar(message, option);
          return key;
        },
        closeSnackbar: (key) => {
          if (key) closeSnackbar(key);
        },
        enqueueWithOption: (message, option, isLocationClose) => {
          var key = enqueueSnackbar(message, option);
          if (isLocationClose) {
            closeSnackbarWhenlocationChange.push(key);
            setCloseSnackbarWhenlocationChange(closeSnackbarWhenlocationChange);
          }
          return key;
        },
      }}
    >
      <GoogleReCaptchaProvider reCaptchaKey="6Leky-MZAAAAAOPKpkaslq1SU6kyztu1SEabps0k">
        <Switch>
          <Route exact path="/:hid/home/" component={HomePage} />
          <Suspense
            fallback={
              <div style={{ height: "90vh" }} className={styles.centerFlex}>
                <CircularProgress />
              </div>
            }
          >
            <Route path="/" component={AppRouter} />
          </Suspense>
        </Switch>
      </GoogleReCaptchaProvider>
    </MessageContext.Provider>
  );
}
