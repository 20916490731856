import React from 'react';
import { IconButton, Badge, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    iconButtonText:{
        fontSize:"11px"
    }
}));

export default function IconTextButton(props) {
    const classes = useStyles(props);
    const {text, badgeContent, badgeColor,...other} = props;
    return (
        <div>
                <IconButton
                    classes={{label:classes.iconButtonLabel}}
                    {...other}
                >
                    <Badge badgeContent={badgeContent} color={badgeColor}>
                        {props.children}
                    </Badge>
                    <Typography noWrap className={classes.iconButtonText}>{text}</Typography>
                    {/* 複数のクラスを指定する場合はclsxを使う */}
                    {/* <Table className={clsx(classes.noMargin,classes.noPadding)}> */}
                        {/* clsxを使わない場合は以下のように書ける */}
                        {/* <TableRow className={`${classes.noMargin} ${classes.noPadding}`}>
                            {props.children}
                        </TableRow>
                        <TableRow className={clsx(classes.noMargin,classes.noPadding)}>
                            <span className={clsx(classes.iconButtonText)}>{text}</span>
                        </TableRow>
                    </Table> */}
                </IconButton>
        </div>
    );
}