import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import React, { lazy, Suspense, useEffect } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import useReactRouter from "use-react-router";
// import HomePage1 from "pages/homePages/HomePage1";
// import HomePage2 from "pages/homePages/HomePage2";
// import HomePage3 from "pages/homePages/HomePage3";
// import HomePage4 from "pages/homePages/HomePage4";
// import HomePage5 from "pages/homePages/HomePage5";
import {
  ApiGetDeptsForHomePage,
  ApiGetHospital,
} from "components/api/HospitalApi";
import NotFound from "pages/shared/NotFound";
import { PageTitle } from "components/common/PageTitle";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import UseEffect2 from "components/common/UseEffect2";
import { commonStyle } from "components/styles/CommonStyle";
import { CircularProgress } from "@material-ui/core";

const HomePage1 = lazy(() => import("pages/homePages/HomePage1"));
const HomePage2 = lazy(() => import("pages/homePages/HomePage2"));
const HomePage3 = lazy(() => import("pages/homePages/HomePage3"));
const HomePage4 = lazy(() => import("pages/homePages/HomePage4"));
const HomePage5 = lazy(() => import("pages/homePages/HomePage5"));

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "99vw",
    position: "relative",
    marginLeft: "-50vw",
    left: "50%",
    top: theme.spacing(1),
  },
}));

export function HomePage(props) {
  const unmountRef = useUnmountRef();
  const styles = commonStyle();
  const { history, location, match } = useReactRouter();
  const [hospital, setHospital] = useSafeState(unmountRef, null);
  const [depts, setDepts] = useSafeState(unmountRef, null);
  const [theme, setTheme] = useSafeState(unmountRef, useTheme());
  const [seoKeywords, setSeoKeywords] = useSafeState(unmountRef, "予約");
  const [seoDescription, setSeoDescription] = useSafeState(
    unmountRef,
    "当医院のホームページです"
  );

  UseEffect2(() => {
    /// SEOキーワードの設定
    SetSeoKeywords(hospital, depts);
    SetSeoDescription(hospital, depts);
  }, [hospital, depts]);

  function SetSeoKeywords(hospital, depts) {
    /// SEOキーワードの設定
    var keywords = "予約";
    // 病院
    if (hospital) {
      keywords = `${keywords},${hospital.name}`;
      // 病院都道府県名
      if (hospital.prefName) keywords = `${keywords},${hospital.prefName}`;
      // 市区町村名
      if (hospital.cityName) keywords = `${keywords},${hospital.cityName}`;
    }
    // 診療科名
    if (depts) {
      var text = "";
      for (let i = 0; i < depts.length; i++) {
        let item = depts[i];
        if (item.isVisibleHomePage) text = `${text},${item.name}`;
      }
      keywords = keywords + text;
    }
    setSeoKeywords(keywords);
  }

  function SetSeoDescription(hospital, depts) {
    var hospitalName = "当医院";
    var prefName = "";
    var cityName = "";
    var description = "";
    // 病院名
    if (hospital) {
      hospitalName = hospital.name;
      if (hospital.prefName) prefName = hospital.prefName;
      if (hospital.cityName) cityName = hospital.cityName;
      if (hospital.description) description = hospital.description;
    }
    setSeoDescription(
      `${prefName ? prefName : ""}${cityName ? cityName : ""}${
        (prefName || cityName) && "の"
      }${hospitalName}のホームページです。${description}`
    );
  }

  //パラメーターを取得
  var hospitalId = props.match.params.hid;

  async function GetHospital(hid) {
    await ApiGetHospital(hid)
      .then(function (response) {
        setHospital(response[0]);

        if (
          response[0].useHomePage == false ||
          response[0].templatePattern == null
        ) {
          history.push("*");
        }
        if (response[0].primaryColor && response[0].secondaryColor) {
          const thm = createMuiTheme({
            palette: {
              primary: {
                main: `${response[0].primaryColor}`,
              },
              secondary: {
                main: response[0].secondaryColor,
              },
              contrastThreshold: 1.5,
            },
          });
          setTheme(thm);
        }

        //なぜかパラメーターから取得した数値を引数にすると,引数がnullになりBadRequestになるので、responseのidから取得する
        GetDepts(response[0].id);
      })
      .catch(function (error) {});
  }
  async function GetDepts(hid) {
    await ApiGetDeptsForHomePage(hid)
      .then(function (response) {
        setDepts(response);
      })
      .catch(function (error) {});
  }
  useEffect(() => {
    GetHospital(hospitalId);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        {hospital && (
          <Fragment>
            {!hospital.isSearchable ? (
              <>
                <Helmet>
                  <meta name="robots" content="noindex, nofollow" />
                </Helmet>
              </>
            ) : (
              <>
                <Helmet>
                  {seoKeywords && (
                    <meta name="keywords" content={`${seoKeywords}`} />
                  )}
                  {seoDescription && (
                    <meta
                      name="description"
                      // content="えみでる Web予約システムは病院、診療所のWeb予約システムです。病院、診療所の予約受付業務の効率化により、混雑の解消、三密の回避につながります。"
                      content={`${seoDescription}`}
                    />
                  )}
                </Helmet>
              </>
            )}
            <PageTitle title={hospital.name} />
            <>
              {hospital.useHomePage ? (
                hospital.templatePattern ? (
                  <Suspense
                    fallback={
                      <div
                        style={{ height: "90vh" }}
                        className={styles.centerFlex}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    {(() => {
                      if (hospital.templatePattern == 1) {
                        return <HomePage1 hospital={hospital} depts={depts} />;
                      } else if (hospital.templatePattern == 2) {
                        return <HomePage2 hospital={hospital} depts={depts} />;
                      } else if (hospital.templatePattern == 3) {
                        return <HomePage3 hospital={hospital} depts={depts} />;
                      } else if (hospital.templatePattern == 4) {
                        return <HomePage4 hospital={hospital} depts={depts} />;
                      } else if (hospital.templatePattern == 5) {
                        return <HomePage5 hospital={hospital} depts={depts} />;
                      }
                    })()}
                  </Suspense>
                ) : (
                  <NotFound />
                )
              ) : (
                <></>
              )}
            </>
          </Fragment>
        )}
      </>
    </ThemeProvider>
  );
}
