export const ApplicationName = "HospitalReservationSystem";

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const LogoutActions = {
  LogoutCallback: "logout-callback",
  Logout: "logout",
  LoggedOut: "logged-out",
};

export const LoginActions = {
  Login: "login",
  LoginCallback: "login-callback",
  LoginFailed: "login-failed",
  Profile: "profile",
  Register: "register",
};

const prefix = "/authentication";

export const ApplicationPaths = {
  DefaultLoginRedirectPath: "/",
  ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  // Login: `${prefix}/${LoginActions.Login}`,
  Login: "/login",
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  // IdentityRegisterPath: '/Identity/Account/Register',
  IdentityManagePath: "/Identity/Account/Manage",
  IdentityRegisterPath: "/register",
  FeedbackPath: "/feedback",
};

export const Claims = {
  Has_All_Claims: "Has_All_Claims",
  Hospital_Create: "Hospital_Create",
  Hospital_Edit: "Hospital_Edit",
  Hospital_Delete: "Hospital_Delete",
  Hospital_Dept_Create: "Hospital_Dept_Create",
  Hospital_Dept_Edit: "Hospital_Dept_Edit",
  Hospital_Dept_Delete: "Hospital_Dept_Delete",
  Doctor_Create: "Doctor_Create",
  Doctor_Edit: "Doctor_Edit",
  Doctor_Delete: "Doctor_Delete",
  Role_Create: "Role_Create",
  Roles_Edit: "Roles_Edit",
  RoleClaims_Edit: "RoleClaims_Edit",
  Time_Table_Create: "Time_Table_Create",
  Time_Table_Edit: "Time_Table_Edit",
  Time_Table_Delete: "Time_Table_Delete",
  User_Create: "User_Create",
  User_Edit: "User_Edit",
  User_Delete: "User_Delete",
  UserClaims_Edit: "UserClaims_Edit",
  Can_Use_Admin_Menu: "Can_Use_Admin_Menu",
  Not_Require_Hospital_Id: "Not_Require_Hospital_Id",
  Doctor_Read: "Doctor_Read",
  Hospital_Read: "Hospital_Read",
  Hospital_Dept_Read: "Hospital_Dept_Read",
  Roles_Read: "Roles_Read",
  User_Read: "User_Read",
  Time_Table_Read: "Time_Table_Read",
  Manage_Administorator: "Manage_Administorator",
  Manage_ImuraEnvelope: "Manage_ImuraEnvelope",
  Manage_TokyoPrinting: "Manage_TokyoPrinting",
  Manage_HospitalAdmin: "Manage_HospitalAdmin",
  Manage_HospitalStaff: "Manage_HospitalStaff",
  Not_Require_Hospital_Dept_Id: "Not_Require_Hospital_Dept_Id",
  Change_Password_Others: "Change_Password_Others",
  ContactList_Read: "ContactList_Read",
  Calendar_Create: "Calendar_Create",
  Calendar_Edit: "Calendar_Edit",
  Calendar_Delete: "Calendar_Delete",
  Calendar_Read: "Calendar_Read",
  Edit_Common_Calendar: "Edit_Common_Calendar",
  Holiday_Create: "Holiday_Create",
  Holiday_Edit: "Holiday_Edit",
  Holiday_Delete: "Holiday_Delete",
  Holiday_Read: "Holiday_Read",
  Notices_Create: "Notices_Create",
  Notices_Read: "Notices_Read",
  Card_Create: "Card_Create",
  Card_Edit: "Card_Edit",
  Card_Delete: "Card_Delete",
  Card_Read: "Card_Read",
  Edit_Account_Info: "Edit_Account_Info",
  Manage_All_Hospitals: "Manage_All_Hospitals",
  Manage_All_Depts: "Manage_All_Depts",
  AllInformation_Create: "AllInformation_Create",
  AllInformation_Read: "AllInformation_Read",
  AllInformation_Edit: "AllInformation_Edit",
  AllInformation_Delete: "AllInformation_Delete",
  Information_Create: "Information_Create",
  Information_Read: "Information_Read",
  Information_Edit: "Information_Edit",
  Information_Delete: "Information_Delete",
  Allow_Use_Homepage: "Allow_Use_Homepage",

  Common_Time_Table_Create: "Common_Time_Table_Create",
  Common_Time_Table_Edit: "Common_Time_Table_Edit",
  Common_Time_Table_Delete: "Common_Time_Table_Delete",
  Common_Time_Table_Read: "Common_Time_Table_Read",

  Can_Use_Various_Settings: "Can_Use_Various_Settings",
  Disp_Invisible_Reservation: "Disp_Invisible_Reservation",

  // 包装材の残り物
  // Customer_Read: 'Customer_Read',
  // Require_Customer_Info: 'Require_Customer_Info',
  // Require_Employee_Number: 'Require_Employee_Number',
  // CustomProductImages_Edit: 'CustomProductImages_Edit',
  // StandardProductImages_Edit: 'StandardProductImages_Edit',
};
