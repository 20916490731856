import axios from "axios";
import {
  ApplicationName,
  ApplicationPaths,
} from "components/api-authorization/ApiAuthorizationConstants";
import { localStorageKeys } from "components/common/Const";
import { UserManager, WebStorageStateStore } from "oidc-client";

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated() {
    const token = await this.getAccessToken();
    return !!token;
  }

  async getAccessToken() {
    return localStorage.getItem(localStorageKeys.LoginJWT);
  }

  async getBelongClaims() {
    // アクセストークン取得
    const token = await this.getAccessToken();
    // ユーザー情報取得
    let claims;
    await axios
      .get("api/getbelongclaims", {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        claims = response.data;
      })
      .catch(function (error) {
        console.log("getbelongclaims error", error);
      });

    return claims;
  }

  async logout(userid, fn, history) {
    var postObj = {};
    postObj["userId"] = userid;
    postObj["roleId"] = "";
    var token = await authService.getAccessToken();

    if (userid && token) {
      await axios
        .post("api/logout", postObj, {
          headers: !token
            ? { Accept: "application/json", "Content-Type": "application/json" }
            : {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
        })
        .then((response) => {
          console.log(userid + "をログアウトさせました。");
          localStorage.removeItem(localStorageKeys.LoginJWT);
          fn();
          history && history.push("/login");
        })
        .catch((error) => {
          console.log("失敗しました");
          // msgctx.enqueue(
          //   error.response.data + "時間をおいて再度実行してください。",
          //   "error"
          // );
          // isSuccess = false;
          history && history.push("/login");
        });
    } else {
      localStorage.removeItem(localStorageKeys.LoginJWT);
      history && history.push("/login");
      fn();
    }
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
