import { makeStyles } from "@material-ui/core";
import {
  AccountCircle,
  ExitToApp,
  PostAdd,
  Settings,
  Close,
} from "@material-ui/icons";
import {
  ApplicationPaths,
  Claims,
} from "components/api-authorization/ApiAuthorizationConstants";
import authService from "components/api-authorization/AuthorizeService";
import { UserContext } from "components/api-authorization/UserContext";
import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import { ChangeHospitalHeader } from "components/input/button/ChangeHospitalButton";
import IconTextButton from "components/input/button/IconTextButton";
import { LayoutContext } from "pages/Layout";
import React, { Fragment, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// //クラスコンポーネントでMaterial-uiのスタイルを当てたい場合はこう書く
//クラスコンポーネントに対してMaterial-uiのスタイルを当てたい場合はこう書く
const useStyles = makeStyles((theme) => ({
  loginLabel: {
    padding: "12px",
  },
  loginLink: {
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
}));

//Drawerを開閉するたびにカートカウントを取得するAPIが実行されるため、
//LoginMenuをfunction化
export default function LoginMenu(props) {
  const userctx = useContext(UserContext);
  const layoutCtx = useContext(LayoutContext);
  const classes = useStyles();
  // const theme = useTheme();
  // const classes = useStyles(props, theme);
  const unmountRef = useUnmountRef();
  const [user, setUser] = useSafeState(unmountRef);

  const history = useHistory();

  const handleLogoutClick = () => {
    var fn = async () => {
      await authService.logout(
        userctx.user.id,
        () => {
          userctx.setUser(null);
        },
        history
      );
    };
    fn();
  };

  useEffect(() => {
    if (userctx && userctx.user) setUser(userctx.user);
    else setUser(null);
  }, [userctx, userctx.user]);

  function authenticatedView(userName, profilePath, logoutPath) {
    return (
      <Fragment>
        <ChangeHospitalHeader />

        <IconTextButton
          component={Link}
          to={profilePath}
          className={classes.loginLink}
          text=" 管理 "
        >
          <Settings />
        </IconTextButton>

        {/* ハンバーガーメニューに表示させる */}
        <IconTextButton
          onClick={handleLogoutClick}
          className={classes.loginLink}
          text="ログアウト"
        >
          <ExitToApp />
        </IconTextButton>
      </Fragment>
    );
  }

  function anonymousView(registerPath, loginPath) {
    return (
      <Fragment>
        {layoutCtx.hospitalId ? (
          <Fragment>
            <IconTextButton
              component={Link}
              to={`/${layoutCtx.hospitalId}/r/sc/0`}
              className={classes.loginLink}
              text="予約"
            >
              <PostAdd />
            </IconTextButton>
            <IconTextButton
              component={Link}
              to={`/${layoutCtx.hospitalId}/mypage/auth/`}
              className={classes.loginLink}
              text="Myページ"
            >
              <AccountCircle />
            </IconTextButton>
          </Fragment>
        ) : (layoutCtx.isRemindResult ? (
          <Fragment>
            <IconTextButton
              component={Link}
              className={classes.loginLink}
              text="閉じる"
              onClick={() => {
                  window.close();
              }}
            >
              <Close />
            </IconTextButton>
          </Fragment>
        ) : (
          <IconTextButton
            className={classes.loginLink}
            component={Link}
            to="/login"
            text="ログイン"
          >
            <ExitToApp />
          </IconTextButton>
        ))}
      </Fragment>
    );
  }
  return (
    <>
      {!user &&
        anonymousView(
          `${ApplicationPaths.Register}`,
          `${ApplicationPaths.Login}`
        )}
      {user && authenticatedView(user.userName, "/manage/", {})}
    </>
  );
  // if (!userctx.user) {
  //   const registerPath = `${ApplicationPaths.Register}`;
  //   const loginPath = `${ApplicationPaths.Login}`;
  //   return anonymousView(registerPath, loginPath);
  // } else {
  //   const profilePath = "/manage/";
  //   const logoutPath = {};
  //   return authenticatedView(userctx.user.userName, profilePath, logoutPath);
  // }
}
