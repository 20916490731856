import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import React, { useContext, useEffect } from "react";
import { Typography, InputLabel, Select, MenuItem } from "@material-ui/core/";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { commonStyle } from "components/styles/CommonStyle";
import { UserContext } from "components/api-authorization/UserContext";
import authService from "components/api-authorization/AuthorizeService";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import { ErrMsg } from "components/common/ErrorMessageProvider";

import useReactRouter from "use-react-router";
import { useForm } from "react-hook-form";

import { GetDivisions } from "components/api/DivisionsApi";
import DefaultSnackbar from "components/snackbar/DefaultSnackbar";
import { reservationPath } from "components/common/Const";
import { LayoutContext } from "pages/Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  smartLineUpLeft: {
    [theme.breakpoints.up("lg")]: {
      float: "left",
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  smartLineUpRight: {
    [theme.breakpoints.up("lg")]: {
      float: "left",
      width: "40%",
      //marginTop: "10px"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "20px",
    },
  },
  rightContentCell: {
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formControl: {
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  date: {
    "& > span": {
      width: "100%",
    },
  },
  dialogBox: {
    [theme.breakpoints.up("md")]: {
      "& > div > div": {
        minWidth: "700px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& > div > div": {
        width: "100vw",
      },
    },
  },
}));

/**
 * ユーザーに紐づく病院IDを変更するダイアログを表示します。
 * @module ChangeHospitalDialog
 * @param {Object} props - Dialogに準じます
 * @param {function} props.handleClose - キャンセル時処理
 //* @param {function} props.handleSubmit - hospitalIdを引数に持つOK時処理/空の場合AspNetUsersのhospitalIdを更新し、UserContextを更新する
 * @param {string} props.okLabel - OKボタンラベル「確定」
 * @param {string} props.cancelLabel - キャンセルボタンラベル「キャンセル」
 * @param {string} props.disable1 - OKボタンdisable
 * @param {object} props.parameter - Submit時、Close時に渡すパラメータ
 */
export default function ChangeHospitalDialog(props) {
  const unmountRef = useUnmountRef();
  const {
    openDialog,
    handleClose,
    handleSubmit,
    isMyPage,
    isHeaderButton,
    title,
    okLabel,
    cancelLabel,
    disable1,
    parameter,
    ...other
  } = props;

  //const { edit ,...other } = props;

  const [errorMessage, setErrorMessage] = useSafeState(unmountRef, "");
  const classes = useStyles();
  const style = commonStyle();
  const theme = useTheme();

  //const selectedId = new URLSearchParams(props.location.search).toString();
  const [action, setAction] = useSafeState(unmountRef, false);

  const [readingHospital, setReadingHospital] = useSafeState(unmountRef, null);

  const [prefNameData, setPrefNameData] = useSafeState(unmountRef, []); // 県名

  const userctx = useContext(UserContext);

  const [hospitalId, setHospitalId] = useSafeState(unmountRef, "");
  const [deptId, setDeptId] = useSafeState(unmountRef, "0");

  const [prefId, setPrefId] = useSafeState(unmountRef, 0);

  const [hospitalSetter, setHospitalSetter] = useSafeState(unmountRef, false);

  const [deptSetter, setDeptSetter] = useSafeState(unmountRef, false);

  const [msg, setMsg] = useSafeState(unmountRef, null);
  const [open, setOpen] = useSafeState(unmountRef, false);
  const [snackbarSeverity, setSnackbarSeverity] = useSafeState(
    unmountRef,
    "error"
  );

  const [isRedirectMypage] = useSafeState(unmountRef, isMyPage);

  const { history, location, match } = useReactRouter();

  //const reservePath = `/:hid${reservationPath}`;
  const layoutCtx = useContext(LayoutContext);

  const [depts, setDepts] = useSafeState(unmountRef, null);

  var object = {};

  async function GetUser() {
    let isSuccess = false;
    const token = await authService.getAccessToken();

    await axios
      .get("api/account", {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.status == 200) {
          isSuccess = true;
        }
        return response;
      })
      .then(function (json) {
        if (!isSuccess) {
          setErrorMessage(json.message);
        } else {
          json.data.hospitalId && setHospitalId(json.data.hospitalId);
          json.data.deptId && setDeptId(json.data.deptId);
        }
      })
      .catch(function (error) {
        console.log("getItem error", error);
      });
  }

  const {
    // register,
    // watch,
    errors,
    // setError,
    // setValue,
    // getValues,
    // clearErrors,
  } = useForm({});

  async function GetHospital() {
    let isSuccess = false;
    const token = await authService.getAccessToken();

    await axios
      .get("api/hospitals/change?prefCd=" + prefId, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.status == 200) {
          isSuccess = true;
        }
        return response;
      })
      .then(function (json) {
        if (!isSuccess) {
          setErrorMessage(json.message);
          setReadingHospital([]);
          setHospitalSetter(true);
        } else {
          setReadingHospital(json.data);
          setHospitalSetter(true);
        }
      })
      .catch(function (error) {
        console.log("test");
      });
  }

  async function GetDepts(hid) {
    let isSuccess = false;
    const token = await authService.getAccessToken();

    await axios
      .get("api/hospitalDepts/change?hid=" + hid, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.status == 200) {
          isSuccess = true;
        }
        return response;
      })
      .then(function (json) {
        if (!isSuccess) {
          setErrorMessage(json.message);
          setDepts([]);
          setDeptSetter(true);
        } else {
          setDepts(json.data);
          setDeptSetter(true);
        }
      })
      .catch(function (error) {
        console.log(hid);
        //setDeptSetter(true);
      });
    //console.log("deptsErr1");
    //await ApiGetDepts(hid)
    //    .then(function (response) {
    //            setDepts(response);
    //            setDeptSetter(true);
    //    }).catch(function (error) {
    //        console.log("deptsErr");
    //        setDeptSetter(true);
    //    });
  }

  const handleChangePref = (event) => {
    //setRoleId(event.target.value);
    setPrefId(event.target.value);
    setHospitalId(0);
    setDeptId("0");
    setHospitalSetter(false);
    setDeptSetter(false);
  };

  const handleChangeHospital = (event) => {
    //setRoleId(event.target.value);
    setHospitalId(event.target.value);
    setDeptId("0");
    setDeptSetter(false);
  };

  const handleChangeDept = (event) => {
    //setRoleId(event.target.value);
    setDeptId(event.target.value);
  };

  useEffect(() => {
    GetDivisions("prefCd", setPrefNameData);
    GetUser();
  }, []);

  useEffect(() => {
    if (!openDialog) {
      GetUser();
      setPrefId(0);
    }
  }, [openDialog]);

  useEffect(() => {
    GetHospital();
  }, [prefId, openDialog]);

  useEffect(() => {
    if (hospitalId) GetDepts(hospitalId);
  }, [hospitalId, openDialog]);

  async function onUpdate() {
    //var form_data = new FormDataToJson('role-form');
    object["hospitalId"] = hospitalId;
    object["deptId"] = deptId;

    const token = await authService.getAccessToken();
    await axios
      .put("api/account/hospital", object, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setMsg("医療機関を更新しました。");
        setSnackbarSeverity("success");
        setOpen(true);
        userctx.setUser(response.data);
        setPrefId(0);
        handleClose();
        isHeaderButton &&
          (layoutCtx.isMypage
            ? history.push("/manage/")
            : history.push(`/${hospitalId}${reservationPath}sc/0`));
      })
      .catch((error) => {
        setMsg("エラーが発生しました。時間をおいて再度実行してください。");
        setSnackbarSeverity("error");
        setOpen(true);
      });
  }
  const handleSnackbarClose = (event) => {
    setOpen(false);
  };

  return (
    <div>
      <DefaultSnackbar
        onClose={handleSnackbarClose}
        open={open}
        autoHideDuration={6000}
        severity={snackbarSeverity}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {msg}
      </DefaultSnackbar>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        className={classes.dialogBox}
        {...other}
      >
        {/*<DialogTitle id="alert-dialog-title">病院選択</DialogTitle>*/}
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {/*<TitleText>医療機関選択</TitleText>*/}
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
              }}
            >
              <Typography
                variant="h4"
                style={{
                  border: 1,
                  borderRadius: 3,
                  fontWeight: "600",
                  textAlign: "center",
                }}
                color={props.color ? props.color : "primary"}
              >
                医療機関選択
              </Typography>
            </Grid>
          </Grid>
          <ErrMsg className={style.errMsg} errors={errors} name="summary" />
          <Grid container direction="row" justify="center" alignItems="stretch">
            <Grid item xs={12} md={10}>
              <form
                id="role-form"
                className={classes.root}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  {prefNameData && prefNameData.length > 1 && (
                    <Grid item xs={9}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel>都道府県</InputLabel>
                        <Select
                          value={prefId}
                          onChange={handleChangePref}
                          label="都道府県"
                        >
                          <MenuItem key={0} value={0}>
                            すべて
                          </MenuItem>
                          {prefNameData.map((pref, index) => {
                            return (
                              <MenuItem key={index} value={index}>
                                &nbsp;{pref}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {hospitalSetter ? (
                    readingHospital && readingHospital.length > 0 ? (
                      <Grid item xs={9}>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel>医療機関名</InputLabel>
                          <Select
                            value={hospitalId}
                            onChange={handleChangeHospital}
                            name="hospitalId"
                            label="医療機関名"
                          >
                            {readingHospital.map((hospital) => {
                              return (
                                <MenuItem key={hospital.id} value={hospital.id}>
                                  &nbsp;{hospital.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        container
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <DialogContentText
                            style={{ textAlign: "center" }}
                            variant={"body2"}
                          >
                            この都道府県にWeb予約システムを導入しているお客様はございません
                          </DialogContentText>
                        </Grid>
                      </Grid>
                    )
                  ) : (
                    <Skeleton>
                      <Box style={{ width: "100%" }}></Box>
                    </Skeleton>
                  )}
                  {deptSetter && (
                    <>
                      {depts && depts.length > 0 ? (
                        <Grid item xs={9}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel>予約項目名</InputLabel>
                            <Select
                              value={deptId}
                              onChange={handleChangeDept}
                              name="deptId"
                              label="予約項目名"
                            >
                              <MenuItem key={0} value={0}>
                                &nbsp;指定しない
                              </MenuItem>
                              {depts.map((dept) => {
                                //TODO セッションが切れるとここでエラーが出る模様
                                return (
                                  <MenuItem key={dept.id} value={dept.id}>
                                    &nbsp;{dept.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          container
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <DialogContentText
                              style={{ textAlign: "center" }}
                              variant={"body2"}
                            >
                              この医療機関には科目が存在しません。
                            </DialogContentText>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {hospitalSetter &&
                    readingHospital &&
                    readingHospital.length > 0 && (
                      <Grid
                        item
                        xs={9}
                        container
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onUpdate}
                            disabled={action}
                            fullWidth
                          >
                            更新
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => handleClose(e, parameter)}
            color="primary"
            autoFocus
          >
            {cancelLabel ? cancelLabel : "キャンセル"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
