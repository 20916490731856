import { makeStyles, withStyles } from "@material-ui/core/styles";

export const commonStyle = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1) + "px !important",
    marginBottom: theme.spacing(1) + "px !important",
    background: "white",
  },
  pageTitle: {
    margin: theme.spacing(2),
  },
  cenerTitleInBox: {
    margin: theme.spacing(5),
  },
  loginLabel: {
    padding: "12px",
  },
  loginLink: {
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  errMsg: {
    color: theme.palette.error.main,
  },
  catchCopy: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  fullWidth: {
    width: "100%",
  },
  toolTip: {
    fontSize: "14px",
  },
  center: {
    textAlign: "center",
  },
  hiddenXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenSm: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenMd: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  visibleXs: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  visibleSm: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  successLight: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  successText: {
    color: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  warningText: {
    color: theme.palette.warning.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  noBorder: {
    border: "none !important",
  },
  sunday: {
    backgroundColor: "#ffe6e6",
  },
  saturday: {
    backgroundColor: "#f0f6ff",
  },
  sundayColor: {
    color: "#ff1f1f",
  },
  saturdayColor: {
    color: "#0067ff",
  },
  "@media print": {
    noPrint: {
      display: "none",
      "& *": {
        display: "none",
      },
    },
    printOnly: {
      display: "inherit",
      "& *": {
        display: "inherit",
      },
    },
  },
  noPaddingXs: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  fab: {
    zIndex: "100",
    position: "fixed",
    [theme.breakpoints.up("xs")]: {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    [theme.breakpoints.down("xs")]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  fabL: {
    width: "62px",
    height: "62px",
    fontSize: "0.9rem",
  },
  fab2L: {
    width: "68px",
    height: "68px",
    fontSize: "0.95rem",
  },
  fab3L: {
    width: "76px",
    height: "76px",
    fontSize: "1.0rem",
  },
  /* 超良い感じに改行してくれるcss */
  lineBreak: {
    wordBreak: "break-all",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    lineBreak: "anywhere",
    whiteSpace: "pre-wrap",
    whiteSpace: "breakSpaces",
  },
  /* 画面中央配置 flex版 */
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const FloatingBackgroundGlobalCss = withStyles({
  "@global": {
    "#root": {
      backgroundColor: "rgba(248,248,248,1)",
    },
  },
})(() => null);
