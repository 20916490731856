import React, { Fragment, useCallback } from "react";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMessages from "../../ErrorMessages.json";
import { commonStyle } from "components/styles/CommonStyle";

/**
 * エラーメッセージのキー
 */
export const ErrMsgKey = {
  required: "requireErrorMessage",
  number: "numberErrorMessage",
  email: "emailErrorMessage",
  phoneNumber: "phoneNumberErrorMessage",
  phoneNumberNoHyphen: "phoneNumberNoHyphenErrorMessage",
  postalCodeRegEx: "postalCodeRegExErrorMessage",
  unexpected: "unexpectedErrorMessage",
  passwordUnmatch: "passwordUnmatchErrorMessage",
  notAgree: "notAgreeErrorMessage",
  passwordPolicy: "passwordPolicyErrorMessage",
  ipRegEx: "ipRegExErrorMessage",
  date: "dateErrorMessage",
  time: "timeErrorMessage",
  positive: "positiveErrorMessage",
  integer: "integerErrorMessage",
  halfAlphaNumericSymbole: "halfAlphaNumericSymboleErrorMessage",
  url: "urlErrorMessage",
  select: "selectErrorMessage",
  checkHospitalDept: "checkHospitalDeptErrorMessage",
};

/**
 * ErrorMessages.jsonからErrMsgKeyに対応するエラーメッセージを取得して返します。
 * @example
 * <TextField name="userName" fullWidth className={classes.textField} label="ユーザーID" variant="outlined" inputRef={register({ required: GetErrorMessage(ErrMsgKey.require) })} />
 * @param {*} errMsgKey - エラー時に表示するメッセージのキー ErrMsgKey.require等
 */
export function GetErrMsg(errMsgKey) {
  return ErrorMessages[errMsgKey];
}

/**
 * エラーメッセージ表示用のコンポーネント
 * @param {Object} props
 * @param {string} name - 入力チェックエラーを表示する際は入力フィールドのname エラーのみ表示したい場合は任意のnameを指定する
 * @param {any} errors - react-hook-formの errros
 * @param {string} message - 表示するエラーメッセージ
 */
export function ErrMsg(props) {
  const style = commonStyle();
  const { messages, className, id, ...other } = props;
  return (
    <div className={className ? className : style.errMsg} id={id}>
      <ErrorMessage {...other}>
        {({ message }) => <span>{message}</span>}
      </ErrorMessage>
    </div>
  );
}

/**
 * エラーメッセージ表示用カスタムフック
 */
export function useErrorMessageProvider() {
  //useで始まるfunctionはカスタムフックといいます
  /**
   * axiosでレスポンスのstatusが400の場合に
   * react-hook-formを使用して、画面にエラー内容を表示します。
   *
   * @example
   * await fetch('api/xxx', object, {
   *
   * }).then(function (response) {
   *
   * }).catch(function (error) {
   *     if (error.response.status == 400)
   *         setValidationErrorsForaxios(error, setError);
   *
   * @param {*} error - axiosのリクエスト結果 exampleを参照してください
   * @param {*} setError - react-hooks-formのsetError関数
   */
  const setValidationErrorsForaxios = useCallback((error, setError) => {
    // if (error) {
    //   console.log("error >>> " + JSON.stringify(error));
    //   if (error.response) {
    //     console.log("response >>> " + JSON.stringify(error.response));
    //     if (error.response.data) {
    //       console.log("data >>> " + JSON.stringify(error.response.data));
    //       if (error.response.data.errors)
    //         console.log("errors >>> " + error.response.data.errors);
    //     }
    //   }
    // }

    try {
      if (error.response.status === 400) {
        //Bad Request
        if (error.response.data.errors !== undefined) {
          // console.log("Error Result");
          // console.log("message >>> " + error.response.data.message);
          setError("summary", {
            type: "manual",
            message: error.response.data.message,
          });
          //ErrorResult
          if (error.response.data.errors.length > 0) {
            for (var field in error.response.data.errors) {
              let err = error.response.data.errors[field];
              if (err.messageForUser !== undefined)
                setError(field, {
                  type: "manual",
                  message: err.messageForUser,
                });
              else {
                setError(field, { type: "manual", message: err[0] });
              }
            }
          }
        } else {
          //ModelState
          for (var key in error.response.data) {
            let errMsg = error.response.data[key].map((err) => (
              <span>
                {err}
                <br />
              </span>
            ));
            setError(key, {
              type: "manual",
              message: <Fragment>{errMsg}</Fragment>,
            });
          }
        }
      }
      // console.log("Error表示終了");
    } catch (e) {
      console.log("Error表示時に例外が発生しました");
    }
  });

  /**
   * fetchでレスポンスのstatusが400の場合に
   * react-hook-formを使用して、画面にエラー内容を表示します。
   *
   * @example
   * fetch('api/account', {
   *     method: 'POST',
   *     headers: {
   *         'Accept': 'application/json',
   *         'Content-Type': 'application/json'
   *     },
   *     body: JSON.stringify(object)
   * }).then(function (response) {
   *
   * }).catch(function (error) {
   *     if (error.status == 400)
   *         setValidationErrorsForFetch(error, setError);
   *
   * @param {*} error - axiosのリクエスト結果 exampleを参照してください
   * @param {*} setError - react-hooks-formのsetError関数
   */
  const setValidationErrorsForFetch = useCallback((error, setError) => {
    if (error.errors !== undefined) {
      setError("summary", { type: "manual", message: error.message });
      //ErrorResult
      if (error.errors.length > 0) {
        for (var field in error.errors) {
          let err = error.errors[field];
          if (err.messageForUser !== undefined)
            setError(field, { type: "manual", message: err.messageForUser });
          else {
            setError(field, { type: "manual", message: err[0] });
          }
        }
      }
    } else {
      //ModelState
      for (var key in error) {
        setError(key, {
          type: "manual",
          message: (
            <Fragment>
              {error[key]}
              <br />
            </Fragment>
          ),
        });
      }
    }
  });

  //最後にreturnすると外側から {setValidationErrorsForFetch, setValidationErrorsForaxios} = useErrorMessageProvider();というように呼び出すことが出来るようになる
  return {
    setValidationErrorsForaxios,
    setValidationErrorsForFetch,
  };
}
