import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LoginMenu from "./api-authorization/MaterialLoginMenu";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { commonStyle } from "./styles/CommonStyle";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  titleImg: {
    height: "44px",
    marginTop: "4px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    "@media print": {
      display: "none",
      "& *": {
        display: "none",
      },
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    //スマホ版navbarレイアウト調整中
    "& > div": {
      "& > a": {
        paddingRight: "2px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    //[theme.breakpoints.up("md")]: {
    //  display: "none",
    //},
  },
  loginLabel: {
    padding: "12px",
  },
  loginLink: {
    color: "inherit !important",
    "&:hover": {
      color: "inherit",
    },
  },
}));

export default function NavBar(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();

  return (
    // <div className={classes.grow}>
    <Fragment>
      <AppBar id="header-appbar" position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.sectionMobile}>
            {/*<div>*/}
            {props.isMypage && ( //TODO isMypageはisManageに変更する
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={props.onDrawerMenuClick}
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
          <Link
            to={
              props.isMypage
                ? "/manage"
                : props.hospital
                ? `/${props.hospital}/r/sc/0`
                : "/"
            }
          >
            <img
              className={classes.titleImg}
              src="/img/logo.png"
              alt="えみでる Web予約システム ロゴ"
            />
          </Link>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <LoginMenu></LoginMenu>
          </div>
          <div className={classes.sectionMobile}>
            <LoginMenu></LoginMenu>
          </div>
        </Toolbar>
      </AppBar>
    </Fragment>
    //bottomNavigationテスト
    //<BottomNavigation
    //    value={value}
    //    onChange={(event, newValue) => {
    //        setValue(newValue);
    //    }}
    //    showLabels
    //    className={classes.root}
    //>
    //    <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
    //    <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
    //    <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
    //</BottomNavigation>
  );
}
