/**
 * @file App.jsをWrapするコンポーネントです
 */
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import App from "App";
import "custom.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import "react-app-polyfill/stable";
import { Helmet } from "react-helmet";

let theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        fontSize: "1.3rem",
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#6473D3",
    },
    secondary: {
      main: "#85D44E",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  containerRoot: { top: "65px" },
  variantWarning: { color: "#575757 !important" },
}));

export default function WrapApp() {
  const classes = useStyles();
  const domain = document.domain;

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        id="snackbar-provider"
        maxSnack={5}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={10000}
        classes={{
          containerRoot: classes.containerRoot,
          variantWarning: classes.variantWarning,
        }}
      >
        <Helmet>
          <meta
            name="keywords"
            content="えみでる,病院,診療所,クリニック,Web予約,東杏印刷株式会社"
          />
          <meta
            name="description"
            content="えみでる Web予約システムは病院、診療所のWeb予約システムです。病院、診療所の予約受付業務の効率化により、混雑の解消、三密の回避につながります。"
          />
        </Helmet>
        {domain !== "emidel-tokyop.com" ? (
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
            {/* デモ用 Google Analytics */}
            {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-D8G7EDSBG2"
            ></script>
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());

              gtag("config", "G-D8G7EDSBG2");
              `}
            </script>
          </Helmet>
        ) : (
          <Helmet>
            {/* 本番用 Google Analytics */}
            {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-PVVJZJT3GG"
            ></script>
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());

              gtag('config', 'G-PVVJZJT3GG');
              `}
            </script>
          </Helmet>
        )}
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
