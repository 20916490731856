import dayjs from "dayjs";

const dayOfWeekJp = ["日", "月", "火", "水", "木", "金", "土"]; // 曜日(日本語表記)
var objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);
export function JsonDateTimeToDate(jsonDate) {
  let date = new Date(jsonDate);
  return DateToString(date);
}

export function JsonDateTimeToJpDate(jsonDate) {
  let date = new Date(jsonDate);
  return DateToJpString(date);
}

export function DateToString(date) {
  return (
    date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
  );
}

export function DateToJpString(date) {
  return (
    date.getFullYear() +
    "年" +
    (date.getMonth() + 1) +
    "月" +
    date.getDate() +
    "日"
  );
}

/**
 * 日付と時刻を合成して日時を返します
 * @return - dayjs
 * @param {Date} date - javascriptのDate
 * @param {string} time - 時刻の文字列
 * @param {string} timeFormat - HH:mm等
 */
export function DateTime(date, time, timeFormat) {
  const d = dayjs(new Date(date));
  const t = dayjs("20200101 " + time, "YYYYMMDD " + timeFormat);
  return dayjs({
    year: d.year(),
    month: d.month(),
    day: d.date(),
    // hour: t.hours(),
    hour: t.hour(),
    minute: t.minute(),
  });
}

/**
 * 時刻にダミーの日付を付加してdayjsオブジェクトを返します。
 * @return - dayjs
 * @param {string} time - 時刻の文字列
 */
export function TimeToDamyDatetime(time) {
  return dayjs("2020-1-1 " + time);
}

/**
 * 指定されたDateの日本語の曜日を取得します (日、月、火・・・)
 * @param {Date} date - Dateオブジェクト
 */
export function GetDayofWeekJp(date) {
  return dayOfWeekJp[date.getDay()];
}

/**
 * 指定された時間帯の文字列を返します
 * @param {string} bgnTime
 * @param {string} endTime
 * @param {string} formatIn - bgnTime, endTimeのフォーマット ex-"HH:mm"
 * @param {string} formatOut  - 出力するフォーマット ex "HH時mm分"
 */
export function GetTimeRangeString(bgnTime, endTime, formatIn, formatOut) {
  return (
    dayjs("20200101 " + bgnTime, "YYYYMMDD " + formatIn).format(formatOut) +
    " ～ " +
    dayjs("20200101 " + endTime, "YYYYMMDD " + formatIn).format(formatOut)
  );
}
/**
 * 指定された時間帯の文字列を返します 出力フォーマット HH:mm
 * @param {string} bgnTime - 開始時刻 "HH:mm・・・"形式
 * @param {string} endTime - 終了時刻 "HH:mm・・・"形式
 */
export function GetTimeRangeStr(bgnTime, endTime) {
  return GetTimeRangeString(bgnTime, endTime, "HH:mm", "HH:mm");
}

/**
 * 日付を比較した結果を返します
 * @returns -1:date1がdate2より古い、 0:同じ日付、1:date1がdate2より新しい
 * @param {Date} date1
 * @param {Date} date2
 */
export function CompareDate(date1, date2) {
  var year1 = date1.getFullYear();
  var month1 = date1.getMonth() + 1;
  var day1 = date1.getDate();

  var year2 = date2.getFullYear();
  var month2 = date2.getMonth() + 1;
  var day2 = date2.getDate();

  if (year1 === year2) {
    if (month1 === month2) {
      if (day1 === day2) {
        return 0;
      } else {
        if (day1 > day2) {
          return 1;
        } else {
          return -1;
        }
      }
    } else {
      if (month1 > month2) {
        return 1;
      } else {
        return -1;
      }
    }
  } else {
    if (year1 > year2) {
      return 1;
    } else {
      return -1;
    }
  }
}
