import { sessionStorageKeys } from "components/common/Const";
import { useContext } from "react";
import { MessageContext } from "components/context/MessageContext";
import axios from "axios";

export const GetRevision = async () => {
  // サーバーバージョンの取得
  let hash = new Date().getTime();
  return await axios
    .get("/revision.txt?" + hash)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("revision.txt error", error);
    });
};

export default async function CheckRevision(msgctx, pattern, errorFunction) {
  var localVersion = sessionStorage.getItem(sessionStorageKeys.Revision);
  var serverVersion = await GetRevision();

  if (localVersion == "init") {
    sessionStorage.setItem(sessionStorageKeys.Revision, serverVersion);
    return;
  }

  const doPattern = (pattern) => {
    if (pattern === 2) {
      // リビジョン更新開始
      msgctx.enqueueNotClose(
        "最新バージョンがあります。画面をリロードします。",
        "info"
      );
      sessionStorage.setItem(
        sessionStorageKeys.Reload,
        "最新バージョンへ更新しました。"
      );
      window.location.reload(true);
    } else if (pattern === 1) {
      msgctx.enqueueNotClose(
        "最新バージョンがあります。画面をリロードしてください。",
        "info"
      );
    }
  };

  if (serverVersion) {
    if (localVersion) {
      // 現在のリビジョンと取得したリビジョンが異なる場合はリロード
      if (localVersion !== serverVersion && localVersion !== "init") {
        sessionStorage.setItem(
          sessionStorageKeys.ServerRevision,
          serverVersion
        );
        doPattern(pattern);
        errorFunction && errorFunction();
      }
    } else {
      doPattern(pattern);
    }
  }
}
