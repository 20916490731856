import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChangeHospitalDialog from "components/dialog/ChangeHospitalDialog";
import { TouchApp } from "@material-ui/icons";
import IconTextButton from "components/input/button/IconTextButton";
import { UserContext } from "components/api-authorization/UserContext";
import { Claims } from "components/api-authorization/ApiAuthorizationConstants";
import { MessageContext } from "components/context/MessageContext";

//クラスコンポーネントに対してMaterial-uiのスタイルを当てたい場合はこう書く
const useStyles = makeStyles((theme) => ({
  loginLabel: {
    padding: "12px",
  },
  loginLink: {
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  confirmHospital: {
    color: "black",
    display: "inline-block",
    cursor: "pointer",
    transition: "0.3s",
  },
}));
/**
 * 病院選択ダイアログ表示のボタンです
 * ApsNatUsersのhospitalIdを更新し、userContextを更新します。
 */
export function ChangeHospitalButton(props) {
  const unmountRef = useUnmountRef();
  const { ...others } = props;
  const [openDialog, setOpenDialog] = useSafeState(unmountRef, false);
  const handleButtonClick = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <ChangeHospitalDialog
        openDialog={openDialog}
        handleClose={() => handleDialogClose()}
      />
      <Button
        onClick={() => handleButtonClick()}
        variant="contained"
        {...others}
      >
        病院選択
      </Button>
    </>
  );
}
//export function ChangeHospitalFunction(props) {
//    const [openDialog, setOpenDialog] = useSafeState(unmountRef, false);
//    const handleButtonClick = () => {
//        setOpenDialog(true);
//    }
//    const handleDialogClose = () => {
//        setOpenDialog(false);
//    }
//    return (
//        <>
//            <ChangeHospitalDialog openDialog={openDialog} handleClose={() => handleDialogClose()} />
//        </>
//    );
//}

export function ChangeHospitalHeader(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useSafeState(unmountRef, false);
  const userctx = useContext(UserContext);
  const theme = useTheme();
  const msg = useContext(MessageContext);
  const [snackbarSeverity, setSnackbarSeverity] = useSafeState(
    unmountRef,
    "error"
  );

  const handleButtonClick = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const [openName, setOpenName] = useSafeState(unmountRef, false);
  const handleHospitalNameChange = () => {
    //setOpenName(!openName);
    msg.enqueue(userctx && userctx.hospital.name);
    //setSnackbarSeverity("success");
    //setOpen(true);
  };

  const [open, setOpen] = useSafeState(unmountRef, false);

  const handleSnackbarClose = (event) => {
    setOpen(false);
  };

  return (
    <>
      {userctx.claims && userctx.hasClaim(Claims.Manage_TokyoPrinting) && (
        <>
          <ChangeHospitalDialog
            openDialog={openDialog}
            handleClose={() => handleDialogClose()}
            isHeaderButton={true}
          />
          <IconTextButton
            onClick={() => handleButtonClick()}
            className={classes.loginLink}
            text="医院選択"
            variant="contained"
          >
            <TouchApp />
          </IconTextButton>

          {/*
                     * 現在の医療機関を確認する処理
                    <div style={{ position: "absolute", top: "68px", right: "0px" }}>
                        {
                            openName &&
                            <div onClick={() => handleHospitalNameChange()} style={{ backgroundColor: "#e0e0e0", borderRadius: "5px 0 0 5px", paddingLeft: "10px", paddingRight: "10px", padding: "0px 6px", }} className={classes.confirmHospital}>

                                <DefaultSnackbar onClose={handleSnackbarClose} open={open} autoHideDuration={6000} severity={snackbarSeverity} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                                    {userctx && userctx.hospital.name}
                                </DefaultSnackbar>

                                <Typography style={{ color: "black" }}>
                                    {userctx && userctx.hospital.name}
                        </Typography>
                            </div>
                        }
                        <div onClick={() => handleHospitalNameChange()} variant="contained" style={{ backgroundColor: theme.palette.secondary.main, borderRadius: "5px", padding: "2px 6px", }} className={classes.confirmHospital}>
                            <LocalHospital />
                        </div>
                    </div>
                    */}
        </>
      )}
    </>
  );
}