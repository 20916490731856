import axios from "axios";
// import authService from 'components/api-authorization/AuthorizeService';

export async function GetDivisions(key, setter) {
  await axios
    .get("api/divisions?key=" + key)
    .then(function (json) {
      // 正常の場合
      if (json.data) {
        // var object = new Array();
        var object = [];
        for (let i = 0; i < json.data.length; i++) {
          let item = json.data[i];
          object[item.divisionValue] = item.divisionText1;
        }
        setter(object);
      }
    })
    .catch(function (error) {
      console.log("GetDivisions error", error);
      throw error;
    });
}
