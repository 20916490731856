import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

/**
 * スタイルを適用したSnackbar
 * @module DefaultSnackbar
 * @example
 * <DefaultSnackbar severity="error" open={true} onClose={handleSnackbarClose}>エラーが発生しました</DefaultSnackbar>
 * const handleSnackbarClose = useCallback(() => {
 *    setIsOpenSnackbar(false);
 * });
 * @param {Object} props - 基本的にpropsはSnackbarに準じます
 * @param {boolean} props.open - 表示/非表示フラグ
 * @param {string} props.severity - Alertの種別、error, warning, info, successを指定可能
 */
export default function DefaultSnackbar(props) {
  const classes = useStyles();
  const { autoHideDuration, anchorOrigin, open, severity, ...other } = props;

  return (
    <div className={classes.root}>
      <Snackbar open={props.open}
        autoHideDuration={ props.autoHideDuration ? props.autoHideDuration : 6000}
        anchorOrigin={props.anchorOrigin ? props.anchorOrigin : { vertical:"top", horizontal:"right"}}
        {...other}
        >
        <Alert severity={ props.severity ? props.severity : ""}>
            {props.children}
        </Alert>
      </Snackbar>
    </div>
  );
}
