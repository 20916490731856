import React from 'react';

/**
 * 共通のメッセージ表示機能を提供するコンテキストです
 * @function -enqueue メッセージを表示します
 * @example
 * const context = useContext(MessageContext);
 * context.enqueue("メッセージ", "error"); //default, info, success, warning, error
 */
export const MessageContext = React.createContext({
    name: '',
});

