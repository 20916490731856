import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";
import { DateToString } from "components/common/DateTimeHelper";

/**
 * 医療機関の情報を取得します
 * @param {int} hid - 医療機関ID
 */
export async function ApiGetHospital(hid) {
  let result;
  await axios
    .get(`api/hospitals/${hid}`)
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}
/**
 * 医療機関の一覧を取得します
 */
export async function ApiGetHospitals() {
  let result;
  const token = await authService.getAccessToken();
  await axios
    .get(`api/hospitals/search?getDeleted=false`, {
      // + queryString.toString(), {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 予約項目の一覧を取得します
 * @param {int} hid - 医療機関ID
 */
export async function ApiGetDepts(hid) {
  let result;
  const token = await authService.getAccessToken();
  await axios
    .get("api/hospitalDepts/byhospital", {
      params: { hid: hid },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 予約項目の一覧を取得します
 * @param {int} hid - 医療機関ID
 */
export async function ApiGetDeptsForHomePage(hid) {
  let result;
  const token = await authService.getAccessToken();
  await axios
    .get("api/hospitalDepts/byhospitalforhomepage", {
      params: { hid: hid },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 予約項目を取得します
 * @param {int} hid - 医療機関ID
 */
export async function ApiGetDept(did) {
  let result;
  await axios
    .get(`api/hospitalDepts/${did}`)
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 予約項目を取得します
 * @param {string} parameter - パラメータストリング
 */
export async function ApiSearchDept(parameter) {
  let result;
  var tkn = await authService.getAccessToken();
  await axios
    .get(`api/hospitalDepts/search?${parameter}`, {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 閲覧可能な予約項目の一覧を取得します
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiGetMyDepts(token) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get("api/hospitalDepts/my?", {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 時間枠の一覧を取得します
 * @param {string} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 * @param {sring} hospitalId - 医療機関ID
 * @param {sring} deptId - 予約項目ID
 * @param {date?} date - 対象の日付
 * @param {time?} time - 対象の時刻
 * @param {int?} dayOfWeek - 対象の曜日
 */
export async function ApiGetTimeTables(
  token,
  hospitalId,
  deptId,
  date,
  time,
  dayOfWeek
) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get("api/timeTables?", {
      params: {
        hid: hospitalId,
        did: deptId,
        date: DateToString(date),
        time: time,
        dayOfWeek: dayOfWeek,
      },
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 予約テーブルを取得します
 * @param {sring} hospitalId - 医療機関ID
 * @param {sring} deptId - 予約項目ID
 * @param {date?} date - 対象の日付
 * @param {int} days - 表示する日数
 */
export async function ApiGetReservationTables(
  hospitalId,
  deptId,
  date,
  days = 0
) {
  let result;
  let tkn = await authService.getAccessToken();
  await axios
    .get("api/Reservations", {
      params: {
        hid: hospitalId,
        did: deptId,
        date: DateToString(date),
        days: days,
      },
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 月間予約状況を取得します
 * @param {sring} hospitalId - 医療機関ID
 * @param {sring} deptId - 予約項目ID
 * @param {date?} bgnDate - 開始日付
 * @param {date?} endDate - 終了日付
 */
export async function ApiGetMonthlyReservationTables(
  hospitalId,
  deptId,
  bgnDate,
  endDate,
  isShowDashboard
) {
  let result;
  let tkn = "";
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get("api/Reservations/m", {
      params: {
        hid: hospitalId,
        did: deptId,
        bgnDate: DateToString(bgnDate),
        endDate: DateToString(endDate),
        isShowDashboard: isShowDashboard,
      },
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}
