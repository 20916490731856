/**
 * @file 404 NotFound時に表示されるページです
 */
import React, {useContext} from 'react';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/Layout';

export default function NotFound()
{
    const layoutCtx = useContext(LayoutContext);
    layoutCtx.setIsMypage(false);
    return (
        <Container maxWidth="xl">
            <div style={{marginTop:"70px"}}>
                お探しのページは見つかりませんでした。<br />
                - 404 NotFound
            </div>
        </Container>
    );
}