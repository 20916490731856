/**
 * @file ナビゲーションバーを表示とユーザー・権限の情報を提供するコンポーネントです。
 */
import {
  useSafeState,
  useUnmountRef,
} from "components/common/UnmountStateHelper";
import React, { useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import NavBar from "components/NavBar";
import authService from "components/api-authorization/AuthorizeService";
import axios from "axios";
import { UserContext } from "components/api-authorization/UserContext";
import useReactRouter from "use-react-router";
import { ApiGetHospital } from "components/api/HospitalApi";
import { MessageContext } from "components/context/MessageContext";
import { ErrMsgKey, GetErrMsg } from "components/common/ErrorMessageProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  mainContainer: {
    flex: "1 0 auto",
  },
  footer: {
    textAlign: "right",
    flexShrink: "0",
    marginTop: "auto",
    paddingRight: "20px",
    maxHeight: "25px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  footerDivider: {
    margin: "20px 0px 0px 0px",
  },
  firstContent: {
    marginTop: theme.spacing(5),
  },
}));

export const LayoutContext = React.createContext();

export function Layout(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const msg = useContext(MessageContext);
  const [isDrawerOpen, setIsDrawerOpen] = useSafeState(unmountRef, false);
  const [user, setUser] = useSafeState(unmountRef, null);
  const [claims, setClaims] = useSafeState(unmountRef, null);
  const [hospitalId, setHospitalId] = useSafeState(unmountRef, null); //マイページを開く用の医療機関ID
  const [hospital, setHospital] = useSafeState(unmountRef, null);
  const [isMypage, setIsMypage] = useSafeState(unmountRef, false);
  const [isRemindResult, setIsRemindResult] = useSafeState(unmountRef, false);

  //TODO UserContextはトークン情報を入れずに、ユーザーの情報と権限の情報を持つようにする
  //AuthorizeServiceにsubscribeして変更があった場合、サーバーから取り直す

  const { history } = useReactRouter();

  const handleDrawerMenuClick = function () {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleMainClick = function () {
    if (isDrawerOpen) setIsDrawerOpen(!isDrawerOpen);
  };

  const handleHospitalIdChange = function (hid) {
    setHospitalId(hid);
  };

  async function GetUser() {
    //ユーザー情報取得

    if (await authService.isAuthenticated()) {
      const token = await authService.getAccessToken();
      await axios
        .get("api/Account/", {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) setUser(response.data);
        })
        .catch((error) => {
          //TODO エラー処理はどうする？
        });
    }
  }

  async function GetClaims() {
    if (await authService.isAuthenticated()) {
      await authService.getBelongClaims().then((result) => {
        if (result) {
          setClaims(result["claims"]);
        }
      });
    }
  }

  const GetHospital = useCallback(() => {
    if (user) {
      ApiGetHospital(user.hospitalId)
        .then(function (response) {
          if (response && response.length > 0) setHospital(response[0]);
        })
        .catch(function (error) {
          msg.enqueue("医療機関データが取得できませんでした", "error");
        });
    }
  });

  useEffect(() => {
    GetUser();
    return () => {
      //Unmount時 テスト
      // alert("Unmount");
    };
    //setIsNoneHeader(false);
  }, []);

  //setIsMypage(false);

  useEffect(() => {
    GetHospital();
    const asyncF = async () => {
      if (await authService.isAuthenticated()) {
        await GetClaims();
      }
    };
    asyncF();
  }, [user]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {/*ユーザー情報を全コンポーネントに伝播するプロバイダ*/}
        <UserContext.Provider
          value={{
            user: user,
            hospital: hospital,
            refreshHospital: GetHospital,
            refreshUser: GetUser,
            setUser: setUser,
            claims: claims,
            setClaims: setClaims,
            hasClaim: (claimName) => {
              if (claims) {
                if (claims[claimName] === "true") {
                  return true;
                }
                if (claims["Has_All_Claims"] === "true") {
                  return true;
                }
              }
              return false;
            },
            checkHospitalDept: (hid, did) => {
              // TODO 所　全ての医療機関の管理が可能な権限があればスルーするようにする
              if (user) {
                if (user.hospitalId && hid) {
                  // 全ての病院を管理可能　でない場合のみチェック
                  if (
                    !(
                      claims &&
                      (claims["Manage_All_Hospitals"] === "true" ||
                        claims["Has_All_Claims"] === "true")
                    )
                  ) {
                    if (user.hospitalId.toString() !== hid.toString()) {
                      // 前画面に戻す
                      history.replace("/admin");
                      // msg.enqueueNotClose("表示できませんでした", "error");
                      msg.enqueueNotClose(
                        GetErrMsg(ErrMsgKey.checkHospitalDept),
                        "error"
                      );
                    } else return true;
                  } else return true;
                }
                if (user.deptId && did) {
                  // 全ての予約項目を管理可能　でない場合のみチェック
                  if (
                    !(
                      claims &&
                      (claims["Manage_All_Depts"] === "true" ||
                        claims["Has_All_Claims"] === "true")
                    )
                  ) {
                    if (user.deptId !== did) {
                      // admin にでもとばす
                      history.replace("/admin");
                      // msg.enqueueNotClose("表示できませんでした", "error");
                      msg.enqueueNotClose(
                        GetErrMsg(ErrMsgKey.checkHospitalDept),
                        "error"
                      );
                    } else return true;
                  } else return true;
                }
              }
            },
          }}
        >
          <LayoutContext.Provider
            value={{
              ToggleMain: handleMainClick,
              ToggleDrawer: handleDrawerMenuClick,
              SetHospitalId: handleHospitalIdChange,
              isDrawerOpen: isDrawerOpen,
              hospitalId: hospitalId,
              setIsMypage: setIsMypage,
              isMypage: isMypage,
              setIsRemindResult: setIsRemindResult,
              isRemindResult: isRemindResult,
            }}
          >
            <NavBar
              onDrawerMenuClick={handleDrawerMenuClick}
              isMypage={isMypage}
              hospital={hospitalId}
            />
            <Container
              className={classes.mainContainer}
              maxWidth="xl"
              disableGutters={true}
            >
              {props.children}
            </Container>
            <hr id="footer-divider" className={classes.footerDivider} />
            <footer id="footer" className={classes.footer}>
              © 2020 - Imura Envelope CO.,INC.
            </footer>
          </LayoutContext.Provider>
        </UserContext.Provider>
      </div>
    </div>
  );
}
