import { createMuiTheme } from "@material-ui/core/styles";

export const systemConst = {
  name: "えみでる Web予約システム",
};

export const RecaptchaSiteKeyV2 = "6Lch1uMZAAAAAJPTou0yk7JrdpHlI8K25Rte90gB";

/**
 * 定数
 */
export const imageConst = {
  logo_image_max_count: 1, // 医療施設ロゴ画像枚数
  hospital_image_max_count: 3, // 医療施設画像枚数
  director_image_max_count: 1, // 院長画像枚数
  hospital_image_compress_size: 0.2, // 医療施設画像の圧縮（200kb）
  hospital_dept_image_max_count: 1, // 予約項目画像枚数
  doctor_image_max_count: 1, // 所属医師画像枚数
};
export const validationConst = {
  phone_reg_exp: /^0\d{1,4}-\d{1,4}-\d{3,4}$/, // 電話番号の正規表現
  phone_reg_exp_no_hyphen: /^0\d{9,10}$/, // 電話番号の正規表現
  ip_reg_exp: /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/, // IPの正規表現
  postal_reg_exp: /^[0-9]{3}-[0-9]{4}$/, // 郵便番号の正規表現
};
export const patientConst = {
  new_patient: "初診",
  not: "再診",
};

/**
 * SessionStorageのキー
 * Login.jsにて、ログインボタン押下時にいったんすべて削除されます。
 */
export const sessionStorageKeys = {
  EntryDefaultValues: "HRS_Entry_Default_Values",
  RolesPageNo: "HRS_Roles_Page_No",
  UsersPageNo: "HRS_Users_Page_No",
  DoctorsPageNo: "HRS_Doctors_Page_No",
  HospitalsPageNo: "HRS_Hospitals_Page_No",
  HospitalDeptsPageNo: "HRS_HospitalDepts_Page_No",
  InformationListPageNo: "HRS_InformationList_Page_No",
  ExamCardsPageNo: "HRS_ExamCards_Page_No",
  InformationListPageNo: "HRS_InformationList_Page_No",
  InformationListAdminPageNo: "HRS_InformationList_Admin_Page_No",
  NoticesPageNo: "HRS_Notices_Page_No",
  BookingsSelectedHospital: "HRS_Bookings_Selected_Hospital", //予約一覧画面で表示した医療機関
  BookingsSelectedDate: "HRS_Bookings_Selected_Date", //予約一覧画面で選択された日付
  BookingsSelectedDept: "HRS_Bookings_Selected_Dept", //予約一覧画面で選択された予約項目
  BookingsSelectedTime: "HRS_Bookings_Selected_Time", //予約一覧画面で選択された時間枠
  NewPatient: "HRS_New_Patient", // 診察券を持っていない患者（初診）かどうかの判定
  ReservationTableDeptId: "HRS_Reservation_Table_Dept_Id", // 予約状況テーブルの予約項目を保持
  PreConfirmSelectValue: "HRS_Pre_Confirm_Select_Value", //事前画面の選択値を保持
  Revision: "HRS_Revision", // バージョン情報
  ServerRevision: "HRS_Server_Revision", // サーバーバージョン情報
  Reload: "HRS_Reload", // リロードフラグ 値がある時は強制リロードした後
};
/**
 * LocalStorageのキー
 */
export const localStorageKeys = {
  LoginJWT: "B502E5CA-A39C-42FA-9845-3F9080654ED2",
};

/**
 * 予約の一連の画面のパス
 */
export const reservationPath = "/r/";

/**
 * 予約枠設定画面のカレンダーに利用する週のリソースIDとリソース名のマッピング
 */
export const weekDayMap = [
  { resourceId: 1, resourceTitle: "月" },
  { resourceId: 2, resourceTitle: "月(祝)" },
  { resourceId: 3, resourceTitle: "火" },
  { resourceId: 4, resourceTitle: "火(祝)" },
  { resourceId: 5, resourceTitle: "水" },
  { resourceId: 6, resourceTitle: "水(祝)" },
  { resourceId: 7, resourceTitle: "木" },
  { resourceId: 8, resourceTitle: "木(祝)" },
  { resourceId: 9, resourceTitle: "金" },
  { resourceId: 10, resourceTitle: "金(祝)" },
  { resourceId: 11, resourceTitle: "土" },
  { resourceId: 12, resourceTitle: "土(祝)" },
  { resourceId: 13, resourceTitle: "日" },
  { resourceId: 14, resourceTitle: "日(祝)" },
];

/**
 * 予約枠設定画面の適用週とタイトルのマッピング
 */
export const otherWeeksMap = [
  { key: "99", title: "毎週" },
  { key: "1", title: "第1週" },
  { key: "2", title: "第2週" },
  { key: "3", title: "第3週" },
  { key: "4", title: "第4週" },
  { key: "5", title: "第5週" },
  { key: "6", title: "第6週" },
];

/**
 * 予約枠設定画面のカレンダーに利用するイベント枠の設定
 */
export const calendarStyle = (event, start, end, isSelected) => {
  const colors = ["#34A853", "#4285F4"];
  var backgroundColor;
  var border = "0px";
  var fontColor = "white";
  if (String(event.id).substr(0, 3) === "new") {
    // 新規追加分は、色固定
    backgroundColor = "white";
    border = "1px solid #6473D3";
    fontColor = "#6473D3";
  } else if (event.allDay === true) {
    // 全日分も色固定
    backgroundColor = "#BB001B";
  } else {
    // その他は、曜日ごとの色にする
    var colorId = Number(event.resourceId) % 2;
    backgroundColor = colors[colorId];
  }

  var style = {
    backgroundColor: backgroundColor,
    color: fontColor,
    border: border,
    display: "block",
  };
  return {
    style: style,
  };
};

/**
 * 必須の※マークを赤色にする設定
 */
export const requireRed = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

/**
 * 利用規約
 */
export const termOfUseHtml = `「Emidel（えみでる）」（以下「本サイト」といいます）は、東杏印刷株式会社（以下「当社」といいます）が医療施設等向けに提供する診療予約システムです。本サイトの利用者には本規約が適用されます。本サイトのご利用に際しては本規約の内容をご確認ください。また、当社は、本サイトのご利用条件を変更することがあります。その際にはこの文書の改訂版を掲載することといたしますので、最新の内容をご確認いただきますようお願い申し上げます。

■ 著作権
本サイトの一切のコンテンツに係る著作権その他の権利は当社に帰属します。これらコンテンツは、日本の著作権法、条約および他国の著作権法にいう著作権により保護されており、私的利用の範囲を超えて利用することはできず、また、権利者の許可なく改変、複製、賃貸、貸与、販売、出版、送信、放送等、方法のいかんを問わず第三者の利用に供することを固く禁じます。ただし、個々の著作物に個別の利用条件が付されている場合は、当該条件が優先されます。

■ 免責事項
当社は、本サイトに情報を掲載するにあたり、十分に検討・確認作業を行っておりますが、その内容に関し、その正確性、有用性、確実性、安全性、特定目的に対する合目的性その他いかなる保証もするものではありません。本サイトのご利用により万一何らかの損害が発生したとしても、当社は一切責任を負いません。
当社は、本サイトの構成、利用条件、URL、およびコンテンツ等を、予告なしに変更または中止する場合や、本サイトの運営を予告なしに中断または中止することがあります。なお、当社は理由のいかんに関わらず、これら変更および本サイトの運用の中断、または中止によって生じるいかなる損害についても責任を負いません。
本サイトからリンクしている、当社以外の第三者が運営するWebサイト（以下「リンクサイト」といいます）の内容は、当該第三者の責任で管理されるものであり、当社の管理下にあるものではありません。当社はリンクサイトの内容について、また、それらのご利用によって生じるいかなる損害についても責任を負いません。

■ 禁止事項
本サイトの利用者は、本サイトの利用にあたって、以下の行為を行ってはならないものとします。
また、違反した場合には予約等が無効になる場合があります。
（１）第三者、当社もしくは当社の業務委託先の著作権、商標権等の知的財産権を侵害する行為または侵害する恐れのある行為
（２）第三者、当社または当社の業務委託先の財産もしくはプライバシーを侵害する行為または侵害する恐れのある行為
（３）第三者、当社または当社の業務委託先に対し不利益もしくは損害を与える行為またはそれらの恐れのある行為
（４）本サイトに関連して営利を目的とした活動を行う行為またはその準備行為
（５）本サイトの利用の権利を第三者に譲渡し、もしくは本サイトを第三者に有償無償を問わず利用させ、または、これについて担保その他の権利を設定する行為
（６）本サイトの運営を妨げる行為
（７）本サイトの信用を毀損する行為
（８）自動化ツール等、人間による端末操作を介さずにサイトへの入力を行うツールを用いる行為
（９）コンピュータウィルス等有害なプログラムを本サイトに関連して使用しまたは提供する行為
（10）本サイトに関して複製、改変、方法のいかんを問わずリバースエンジニアリングを行う行為
（11）本サイトと同一ないし類似品を開発・製造、販売または利用許諾する行為
（12）前各号に定める行為を助長する行為
（13）その他、法令に違反するまたは違反の恐れがある行為
（14）その他、当社が不適切と判断する行為

■ 個人情報の取得および利用
本サイトにおける個人情報の取得は、本サイト上で提供するサービスの充実ならびに円滑な提供（予約情報の発信等）および当サイトの円滑な運営を目的として、その目的の達成に必要な範囲内で行います。取得した個人情報は、取得目的の範囲内で利用し、これらの目的以外で利用する場合には、利用者に対し事前に確認または同意を求めます。
なお、個人情報の取得は、本サイト上での利用者本人による入力により取得を行い、本サイト上での個人情報の取得にあたっては、個人情報ご入力時における確認欄等へのクリックによって本人の同意を得たものとします。

■ 損害賠償
本サイトの利用者が本サイトの利用条件に違反して当社に損害を与えた場合、当該利用者は当社または第三者等が被った損害の全額を賠償する義務を負うものとします。

■ 準拠法および管轄裁判所
本サイトは法律の異なる全世界の国々からアクセスすることが可能ですが、本サイトにアクセスされた利用者および当社の両者は、かかる法律の原理の違いに関わらず、本サイトの利用に関して日本国の法律および東京都の条令に拘束されることに同意するものとします。また、本サイトの利用に関して、利用者と当社との間の一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。なお、本サイトへのアクセスは利用者の自由意志によるものとし、本サイトの利用に関しての責任は利用者にあるものとします。

2021年1月15日制定`;
